import { useEffect } from "react";

const LoaderBack = () =>{

    useEffect( () =>{
        // setTimeout(() =>{

        //     document.getElementById("LoadBack").classList.add('removeB');
    
        // }
        // ,1000)

    })
    

    return(
        <div id="LoadBack" className="LoaderBackground">

        </div>
    )
}

export default LoaderBack;