import React, {useEffect, Suspense} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
const HomeTwo = React.lazy( ()=> import('./pages/HomeTwo'));
const PrivacyPolicy = React.lazy( ()=> import  ( './pages/PrivacyPolicy'));
const Ip = React.lazy( ()=> import  ( "./pages/Ip" ));
const  ipDetail = React.lazy( ()=> import  ( "./pages/ipDetail"));
const AboutUs =  React.lazy( ()=> import  ( "./pages/Aboutus"));
const Contact = React.lazy( ()=> import  ( './pages/Contact'));
const ScheduleDemo = React.lazy( ()=> import  ( "./pages/ScheduleDemo"));
const Yant = React.lazy( ()=> import  ( "./pages/Yant"));
const Best8Eats =  React.lazy( ()=> import  ( "./pages/Best8Eats"));
import Loader from 'react-loaders'
import LoaderBack from "./pages/LoaderBack";

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';
import './container/stickyside/sticky.css'





function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
        <Suspense fallback ={<><LoaderBack/><Loader type="line-scale"/></>}>

            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeTwo}/>
              <Route path={`${process.env.PUBLIC_URL + "/about-Us"}`} component ={AboutUs} />
              <Route path={`${process.env.PUBLIC_URL + "/schedule-a-demo"}`} component ={ScheduleDemo} />
              <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} component ={PrivacyPolicy} />
              <Route path={`${process.env.PUBLIC_URL + "/yant"}`} component ={Yant} />
              <Route path={`${process.env.PUBLIC_URL + "/best8eats"}`} component ={Best8Eats} />
              <Route path={`${process.env.PUBLIC_URL + "/Intellectual-Property"}`} component ={Ip} />
              <Route path={`${process.env.PUBLIC_URL + "/ipDetail/:id"}`} component ={ipDetail} />
              <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component ={Contact} />
            </Switch>
            </Suspense>
          </NavScrollTop>
      </Router>
  );
}

export default App;
